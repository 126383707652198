import consumerApi from "api/services/consumer-api";
import { DispensaryPromotion } from "custom-types/Promotions";
import logError from "utils/logError";

type Response = {
  data: DispensaryPromotion[];
  metadata: {
    totalCount: number;
  };
};

export const getDispensaryPromotions = async (
  id: number,
  skip = 0,
  take = 10,
): Promise<Response> => {
  try {
    const { data } = await consumerApi.get(
      `api/dispensaries/v1/${id}/promotions`,
      { params: { skip, take } },
    );

    return data;
  } catch (e) {
    logError(e.message, {
      functionName: "getDispensaryPromotions",
      service: "consumer",
      statusCode: e.statusCode,
    });

    return {
      data: [],
      metadata: {
        totalCount: 0,
      },
    };
  }
};
