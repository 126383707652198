import classnames from "classnames";

import ExpandableContainer from "components/botanic/ExpandableContainer";

import ValidDatesLabel from "../ValidDatesLabel";

const PromotionCard: React.FC<{
  className?: string;
  content: string;
  endDate: string;
  finePrint: string;
  startDate: string;
  timeZone: string;
  title: string;
}> = ({
  className,
  content,
  endDate,
  finePrint,
  startDate,
  title,
  timeZone,
}) => {
  const hideExpander = (content?.length || 0) + (finePrint?.length || 0) < 150;

  return (
    <div className={classnames("bg-leafly-white pt-lg px-lg", className)}>
      <div className="mb-xxs">{title}</div>
      <ValidDatesLabel
        timeZone={timeZone}
        isDeal={false}
        startDate={startDate}
        endDate={endDate}
      />
      <hr className="mt-xs mb-md border-deep-green-alt" />
      <ExpandableContainer
        hide={hideExpander}
        theme="leafly-white"
        initialHeight="72px"
      >
        <p className="text-sm whitespace-pre-wrap">{content}</p>
        {finePrint && <p className="text-xs mt-sm">{finePrint}</p>}
      </ExpandableContainer>
    </div>
  );
};

export default PromotionCard;
