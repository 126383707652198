import React from "react";
import classnames from "classnames";

type ValidDatesLabelProps = {
  endDate?: string;
  isDeal: boolean;
  startDate?: string;
  timeZone: string;
};

const ValidDatesLabel: React.FC<ValidDatesLabelProps> = ({
  endDate,
  isDeal,
  startDate,
  timeZone,
}) => {
  const formatDate = (date: string) =>
    new Date(date).toLocaleDateString("en-US", {
      day: "numeric",
      month: "numeric",
      timeZone,
      year: "numeric",
    });

  let validCopy = "";
  const formattedStartDate = startDate && formatDate(startDate);
  const formattedEndDate = endDate && formatDate(endDate);

  if (startDate && endDate) {
    validCopy = `Valid ${formattedStartDate} - ${formattedEndDate}`;
  } else if (startDate) {
    validCopy = "Ongoing deal";
  } else if (endDate) {
    validCopy = `Valid through ${formattedEndDate}`;
  } else {
    validCopy = "Valid now";
  }

  return (
    <div
      className={classnames("text-xs", {
        "font-bold": isDeal,
        "text-deep-green": !isDeal,
        "text-deep-green-60": isDeal,
      })}
      data-testid="valid-dates-label"
    >
      {validCopy}
    </div>
  );
};

export default ValidDatesLabel;
