import { useEffect, useRef, useState } from "react";
import orderBy from "lodash/orderBy";
import { useInView } from "react-intersection-observer";

import { getDispensaryPromotions } from "api/requests/getDispensaryPromotions";
import { DispensaryPromotion } from "custom-types/Promotions";

const useDispensaryPromotions = (id: number, perPage = 3) => {
  const [page, setPage] = useState(1);
  const [promotions, setPromotions] = useState<DispensaryPromotion[]>();

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const totalCount = useRef<number>();
  useEffect(() => {
    if (inView) {
      const getPromotions = async () => {
        const { data, metadata } = await getDispensaryPromotions(
          id,
          perPage * (page - 1),
          perPage,
        );

        if (totalCount.current === undefined) {
          totalCount.current = metadata.totalCount;
        }
        setPromotions(
          orderBy(
            promotions ? [...promotions, ...data] : data,
            ["orderNumber"],
            "asc",
          ),
        );
      };

      getPromotions();
    }
  }, [inView, page, id]);

  return {
    page,
    promotions,
    ref,
    setPage,
    totalCount: totalCount.current || 0,
  };
};

export default useDispensaryPromotions;
